import React from "react";

function FincraLogo({ fill = "#121212" }) {
  return (
    <svg
      width="105"
      height="26"
      viewBox="0 0 105 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d={`M32.6809 16.6192C32.6809 25.5452 25.4389 32.7821 16.5049 32.7821C14.0546 
          32.7839 11.636 32.2288 9.43214 31.1588C7.22829 30.0887 5.29687 
          28.5318 3.78408 26.6059C5.3114 24.793 7.18911 23.6465 9.7876 
          23.8614C10.4667 23.9186 11.1579 23.8484 11.8404 23.8727C13.2975 23.9264 
          14.6158 23.4792 15.8413 22.753C17.8821 21.5484 19.3001 19.7554 20.5005 17.7614C20.8474 17.1877 20.7746 16.9633 20.0435 16.971C17.3765 16.9997 14.7095 
          16.9936 12.0426 16.9841C7.65399 16.9713 4.58228 18.9718 2.82744 
          22.9853C2.62612 23.4451 2.45829 23.919 
          2.32527 24.403C1.01256 22.0186 0.326245 19.3405 0.330464 
          16.6192C0.330464 7.69327 7.57246 0.454594 16.5058 0.454594C19.1967 0.452703 
          21.8455 1.12248 24.2116 2.40311C26.5778 3.68372 28.5863 5.53462 30.0547 
          7.78773C24.8457 7.76694 19.6376 7.57109 14.4346 7.86053C10.1172 8.09971 7.10759 
          12.2741 8.14054 16.6764C9.33743 
          14.9432 10.9602 14.6043 12.7763 14.6156C16.3114 14.639 19.8457 
          14.5775 23.3808 14.5836C24.9542 14.5836 26.373 14.1425 27.6306 13.23C28.9715 
          12.2585 30.2178 11.1753 31.1267 9.76362C31.1328 9.75575 31.1389 9.74623 31.1441 9.73846C32.1595 11.8902 32.6844 14.2403 32.6809 16.6192Z`}
          fill={fill}
        />
        <path
          d={`M46.1473 8.86914C43.237 8.86914 41.3937 10.6866 41.3937 
          13.6673V15.3879H39.0411V17.7384H41.3937V27.8194H44.11V17.7384H47.1659V15.3879H44.11V13.5946C44.11 12.1406 
          45.0559 11.2439 46.4383 11.2439C46.6566 11.2439 46.9233 11.2682 47.2144 11.2924V8.94183C46.8991 8.89337 
          46.5353 8.86914 46.1473 8.86914ZM49.6397 9.88695C48.9848 10.5412 48.9848 11.6074 49.6397 12.286C50.3188 12.9403 
          51.3859 12.9403 52.065 12.286C52.744 11.6074 52.744 
          10.5412 52.065 9.88695C51.3859 9.2084 50.3188 9.2084 
          49.6397 9.88695ZM52.2105 15.3879H49.4942V27.8194H52.2105V15.3879ZM61.131 15.1698C59.4091 15.1698 58.1964 15.7998 57.493 17.0357V15.3879H54.9223V27.8194H57.6386V20.7192C57.6386 18.9744 58.8027 17.6899 60.5247 17.6899C62.1739 17.6899 63.241 18.8531 63.241 20.6949V27.8194H65.9816V20.3314C65.9816 
          18.7563 65.5208 17.4961 64.6234 16.5752C63.7261 
          15.6301 62.5619 15.1698 61.131 15.1698ZM74.0404 
          15.1698C72.1971 15.1698 70.6935 15.7514 69.5293 16.8903C68.3894 18.0293 67.8073 19.6044 67.8073 21.6158C67.8073 23.6028 68.3894 25.178 69.5536 26.3412C70.7177 27.4802 72.2214 28.0618 74.0404 28.0618C77.0719 28.0618 79.3033 26.2927 79.7883 23.4817L77.1205 23.2393C76.8537 24.7903 75.7138 25.7111 74.0404 
          25.7111C72.0032 25.7111 70.5722 24.1602 70.5722 
          21.6158C70.5722 19.0228 71.9789 17.4961 74.0404 17.4961C75.6895 17.4961 
          76.9022 18.4897 77.0963 20.1133L79.764 19.871C79.3517 16.963 77.0719 15.1698 74.0404 15.1698ZM87.7187 15.2424C85.9481 15.2424 84.784 15.8725 84.1777 17.1326V15.3879H81.5341V27.8194H84.2504V21.0584C84.2504 19.2166 85.6086 17.8838 87.7187 17.8838V15.2424ZM93.501 15.1698C92.0216 15.1698 90.809 
          15.6059 89.9116 16.5026C89.0142 17.3749 88.5535 
          18.3928 88.5535 19.556L91.294 19.6529C91.294 
          18.4654 92.0701 17.5446 93.5252 17.5446C95.0047 
          17.5446 95.7323 18.417 95.7323 20.1376V20.5011L92.7734 20.6707C89.7418 
          20.8403 88.2139 22.1004 88.2139 24.4268C88.2139 26.7289 89.8389 28.0618 92.3611 28.0618C93.6466 28.0618 94.7864 27.6013 95.7323 26.7047V27.8194H98.4725V20.0891C98.4725 16.9146 96.7024 15.1698 93.501 
          15.1698ZM95.7566 23.0455C95.7566 24.863 94.641 
          25.7838 92.8219 25.7838C91.5851 25.7838 90.9545 25.3234 90.9545 
          24.3783C90.9545 23.3848 91.6821 22.8516 93.1615 22.779L95.7566 22.6093V23.0455Z`}
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default FincraLogo;
