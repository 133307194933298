import React from "react";

function QuidaxLogo({ fill = "#121212" }) {
  return (
    <svg
      width="101"
      height="26"
      viewBox="0 0 101 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_942_41)">
        <path
          d="M25.606 16.6716V6.68303H29.9605V16.4687C29.9605 18.8087 30.9264 20.143 32.607 20.143C34.7677 20.143 36.1861 18.0059 36.1861 14.7973V6.68018H40.5407V23.3802H36.1861V21.223C35.1872 23.3287 33.3134 23.9173 30.9595 23.9173C27.5377 23.9173 25.606 21.2773 25.606 16.6687V16.6716Z"
          fill={fill}
        />
        <path
          d="M46.9898 0.654297H42.6353V4.31715H46.9898V0.654297Z"
          fill={fill}
        />
        <path
          d="M46.9733 6.68262H42.6187V23.3826H46.9733V6.68262Z"
          fill={fill}
        />
        <path
          d="M61.5548 0V9.79143C60.52 7.52286 58.5552 6.15143 56.0385 6.15143C51.9101 6.15143 49.0706 9.76 49.0706 15.0029C49.0706 20.2457 51.9736 23.92 56.1047 23.92C58.6186 23.92 60.5559 22.5857 61.5576 20.3114V23.3829H65.9122V0H61.5548ZM57.5314 20.1286C55.3237 20.1286 53.5355 17.8857 53.5355 15.12C53.5355 12.3543 55.3265 10.1114 57.5314 10.1114C59.7363 10.1114 61.5272 12.3543 61.5272 15.12C61.5272 17.8857 59.7363 20.1286 57.5314 20.1286Z"
          fill={fill}
        />
        <path
          d="M92.3046 18.7428L89.0456 23.3856H83.819L89.6913 15.037L83.819 6.68848H89.0456L92.3046 11.3313L95.5637 6.68848H100.79L94.9179 15.037L100.79 23.3856H95.5637L92.3046 18.7428Z"
          fill={fill}
        />
        <path
          d="M67.9874 18.997C67.9874 16.0513 70.2392 14.3341 74.7124 13.7798C77.9659 13.3598 78.4985 13.0998 78.4351 11.9341C78.3716 10.8998 77.5299 10.3455 76.026 10.3455C73.9618 10.3455 72.6152 11.2827 72.6152 12.6455L68.4234 12.3227C68.4234 8.98555 71.3623 6.68555 75.6203 6.68555C79.8783 6.68555 82.7234 9.08269 82.7234 12.8398V23.3998H78.5013V20.7113C77.5962 22.7198 75.7804 23.9198 73.3685 23.9198C70.2116 23.9198 67.9874 21.8798 67.9874 18.9941V18.997ZM78.4985 15.0455C77.8418 15.8541 76.5586 16.4084 74.9939 16.6027C73.1174 16.8627 72.3006 17.5084 72.3006 18.5798C72.3006 19.6513 73.2057 20.2655 74.4917 20.2655C76.8373 20.2655 78.3413 18.2255 78.4958 15.0484L78.4985 15.0455Z"
          fill={fill}
        />
        <path
          d="M23.5887 21.9398C22.4794 23.4455 21.1107 24.7341 19.546 25.7312C19.3418 24.5998 19.0134 23.5112 18.5691 22.4912C17.9592 21.0941 17.1396 19.8141 16.1517 18.697C14.5346 16.8712 12.4594 15.4855 10.1221 14.7255C10.7154 14.637 11.3252 14.5941 11.9406 14.5941C14.2035 14.5941 16.3366 15.1884 18.1966 16.2398C18.7595 15.1627 19.0851 13.9227 19.0851 12.6084C19.0851 8.39982 15.7875 4.98553 11.7226 4.98553C7.65779 4.98553 4.36011 8.39982 4.36011 12.6084C4.36011 13.137 4.41254 13.657 4.50913 14.1541C4.66366 14.9284 4.9341 15.6655 5.29836 16.3341C5.31768 16.3741 5.33148 16.3998 5.35355 16.4312C6.62571 18.7055 9.00169 20.2312 11.7199 20.2312C12.5781 20.2312 13.4004 20.0798 14.1676 19.7941C15.2328 20.8827 16.0938 22.1855 16.6871 23.6284C15.1831 24.357 13.5053 24.7598 11.7392 24.7598C7.66607 24.7598 4.07587 22.6112 1.97585 19.3484C1.59227 18.7598 1.26112 18.137 0.982404 17.4798C0.69265 16.7998 0.463607 16.0827 0.298033 15.3398C0.102103 14.4598 0 13.5455 0 12.6084C0 5.89696 5.25697 0.454102 11.7392 0.454102C18.2214 0.454102 23.4784 5.89696 23.4784 12.6084C23.4784 14.9855 22.8216 17.2084 21.6736 19.0827C22.4242 19.9427 23.0672 20.8998 23.586 21.9398H23.5887Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_942_41">
          <rect width="101" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default QuidaxLogo;
