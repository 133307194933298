import React from "react";

function DandeLogo({ fill = "#121212" }) {
  return (
    <svg
      width="68"
      height="26"
      viewBox="0 0 88 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_942_69)">
        <path
          d="M76.7648 1.24087C76.7648 1.92618 76.2279 2.48173 75.5664 2.48173C74.9041 2.48173 74.3679 1.92618 74.3679 1.24087C74.3679 0.555555 74.9041 0 75.5664 0C76.2279 0 76.7648 0.555555 76.7648 1.24087Z"
          fill={fill}
        />
        <path
          d="M81.1839 3.4126L78.3748 4.96368L75.73 3.4126L76.8921 8.99653H85.475L86.6379 3.4126L83.9924 4.96368L81.1839 3.4126Z"
          fill={fill}
        />
        <path
          d="M85.3785 11.6329V9.92676H76.9894V11.6329H85.3785Z"
          fill={fill}
        />
        <path
          d="M86.8016 2.48173C87.4631 2.48173 88 1.92618 88 1.24087C88 0.555555 87.4631 0 86.8016 0C86.1394 0 85.6031 0.555555 85.6031 1.24087C85.6031 1.92618 86.1394 2.48173 86.8016 2.48173Z"
          fill={fill}
        />
        <path
          d="M82.3824 1.24087C82.3824 1.92618 81.8455 2.48173 81.1839 2.48173C80.5217 2.48173 79.9855 1.92618 79.9855 1.24087C79.9855 0.555555 80.5217 0 81.1839 0C81.8455 0 82.3824 0.555555 82.3824 1.24087Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5175 28.2898C15.9122 28.677 16.1095 28.9566 16.1095 29.1287V29.2254C16.1095 29.5266 15.9329 29.6771 15.5798 29.6771H12.3392C11.9653 29.6771 11.7576 29.4728 11.7161 29.0642L11.6226 27.9349C10.5839 29.3115 9.12974 29.9998 7.26017 29.9998C5.26597 29.9998 3.5522 29.2577 2.11885 27.7737C0.706287 26.2895 0 24.3323 0 21.9019C0 19.5575 0.695898 17.6325 2.0877 16.1269C3.50026 14.6213 5.2452 13.8685 7.32249 13.8685C8.94279 13.8685 10.3035 14.417 11.4045 15.5139V9.44855C11.4045 9.0399 11.2798 8.7065 11.0305 8.44841C10.802 8.19033 10.5631 7.98593 10.3138 7.83543C10.0853 7.66331 9.97105 7.48054 9.97105 7.28692V7.22238C9.97105 6.94281 10.158 6.72773 10.532 6.57717L13.8349 5.48024C14.5619 5.22214 14.9255 5.41572 14.9255 6.06097V26.9993C14.9255 27.4725 15.1228 27.9027 15.5175 28.2898ZM8.10148 28.7738C8.9532 28.7738 9.71143 28.4081 10.3761 27.6769C11.0617 26.9456 11.4045 25.9239 11.4045 24.6119V19.4499C11.4045 18.3745 11.0097 17.3851 10.2203 16.4817C9.431 15.5569 8.50655 15.0944 7.44713 15.0944C6.28386 15.0944 5.34907 15.5892 4.64278 16.5786C3.9365 17.5464 3.57297 18.88 3.5522 20.5791C3.51065 22.8375 3.90534 24.7625 4.73626 26.3541C5.58796 27.9457 6.70971 28.7522 8.10148 28.7738Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.2563 26.7737C32.2979 27.2469 32.4121 27.6233 32.5991 27.9028C32.8068 28.161 33.0041 28.3223 33.1911 28.3868C33.3989 28.4513 33.5754 28.5374 33.7208 28.6449C33.887 28.7525 33.9702 28.8923 33.9702 29.0643C33.9702 29.6021 33.1288 29.8708 31.4462 29.8708C30.6153 29.8708 30.0025 29.7418 29.6077 29.4838C29.2338 29.2041 29.0157 28.6879 28.9534 27.9351C28.1225 29.3116 26.6788 29.9999 24.6222 29.9999C22.8773 29.9999 21.4647 29.5805 20.3845 28.7417C19.3251 27.8813 18.7954 26.6769 18.7954 25.1283C18.7746 23.6012 19.3043 22.4182 20.3845 21.5794C21.4647 20.7191 22.8254 20.2889 24.4664 20.2889C25.9621 20.2889 27.167 20.6545 28.0809 21.3858L28.0186 20.7406C27.8317 18.7618 27.4993 17.3208 27.0215 16.4174C26.5437 15.514 25.7855 15.0624 24.7469 15.0624C24.1029 15.0624 23.5628 15.2667 23.1266 15.6754C22.7111 16.084 22.4203 16.5357 22.2541 17.0304C22.1087 17.5035 21.8906 17.9445 21.5997 18.3532C21.3089 18.7618 20.9454 18.9662 20.5092 18.9662C20.0521 18.9662 19.6782 18.8371 19.3874 18.579C19.1173 18.2994 18.9823 17.9338 18.9823 17.4821C18.9823 16.4496 19.5224 15.5894 20.6026 14.9011C21.7036 14.2128 23.085 13.8687 24.7469 13.8687C26.6995 13.8687 28.2783 14.4709 29.4831 15.6754C30.688 16.8583 31.4047 18.493 31.6331 20.5793L32.2563 26.7737ZM25.6505 28.774C26.5022 28.774 27.1877 28.4513 27.707 27.8061C28.2471 27.1608 28.4964 26.3973 28.4548 25.5155L28.3614 24.5153C28.2991 23.5904 27.9979 22.8591 27.4577 22.3214C26.9177 21.7838 26.2321 21.5149 25.4012 21.5149C24.5287 21.5149 23.8225 21.8052 23.2824 22.386C22.7422 22.9452 22.4722 23.7087 22.4722 24.6766C22.4722 25.8165 22.7631 26.7844 23.3447 27.5802C23.9471 28.376 24.7157 28.774 25.6505 28.774Z"
          fill={fill}
        />
        <path
          d="M51.9332 28.2577C51.58 27.8706 51.3931 27.4404 51.3723 26.9672L50.9984 20.8696C50.8529 18.4822 50.2298 16.7185 49.1288 15.5786C48.0278 14.4387 46.6776 13.8687 45.078 13.8687C44.0186 13.8687 43.0735 14.116 42.2425 14.6107C41.4116 15.1054 40.778 15.7937 40.3418 16.6755V14.5784C40.3418 14.2773 40.2587 14.073 40.0925 13.9655C39.9471 13.8364 39.7082 13.8472 39.3758 13.9977L36.3534 15.514C35.9794 15.6861 35.7925 15.8797 35.7925 16.0948V16.1916C35.7925 16.3852 35.9067 16.5679 36.1352 16.74C36.3638 16.8906 36.5922 17.0949 36.8207 17.353C37.07 17.6111 37.1947 17.9445 37.1947 18.3532V26.9995C37.1947 27.4727 36.987 27.8921 36.5715 28.2577C36.156 28.6234 35.9483 28.903 35.9483 29.0966V29.2256C35.9483 29.5482 36.1664 29.7096 36.6026 29.7096H41.4324C41.8894 29.7096 42.1179 29.5482 42.1179 29.2256V29.0966C42.1179 28.903 41.8997 28.6234 41.4635 28.2577C41.0481 27.8921 40.8403 27.4727 40.8403 26.9995V19.4178C40.8403 18.2349 41.1416 17.2777 41.744 16.5465C42.3464 15.7937 43.1358 15.4172 44.1121 15.4172C46.1479 15.4172 47.2384 17.1917 47.3838 20.7406L47.6955 26.9672C47.7162 27.4404 47.5293 27.8706 47.1346 28.2577C46.7606 28.6234 46.5737 28.903 46.5737 29.0966V29.2256C46.5737 29.5482 46.8022 29.7096 47.2592 29.7096H51.8085C52.2655 29.7096 52.4941 29.5482 52.4941 29.2256V29.0966C52.4941 28.903 52.3071 28.6234 51.9332 28.2577Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.3452 28.2898C70.7396 28.677 70.9369 28.9566 70.9369 29.1287V29.2254C70.9369 29.5266 70.7602 29.6771 70.4075 29.6771H67.1668C66.7928 29.6771 66.5851 29.4728 66.5436 29.0642L66.4501 27.9349C65.4114 29.3115 63.9573 29.9998 62.0877 29.9998C60.0935 29.9998 58.3797 29.2577 56.9464 27.7737C55.5338 26.2895 54.8275 24.3323 54.8275 21.9019C54.8275 19.5575 55.5234 17.6325 56.9152 16.1269C58.3278 14.6213 60.0727 13.8685 62.15 13.8685C63.7704 13.8685 65.131 14.417 66.232 15.5139V9.44855C66.232 9.0399 66.1073 8.7065 65.8581 8.44841C65.6295 8.19033 65.3907 7.98593 65.1414 7.83543C64.9129 7.66331 64.7986 7.48054 64.7986 7.28692V7.22238C64.7986 6.94281 64.9856 6.72773 65.3595 6.57717L68.6626 5.48024C69.3892 5.22214 69.7528 5.41572 69.7528 6.06097V26.9993C69.7528 27.4725 69.95 27.9027 70.3452 28.2898ZM62.929 28.7738C63.7807 28.7738 64.5389 28.4081 65.2037 27.6769C65.8892 26.9456 66.232 25.9239 66.232 24.6119V19.4499C66.232 18.3745 65.8372 17.3851 65.0479 16.4817C64.2585 15.5569 63.3341 15.0944 62.2746 15.0944C61.1114 15.0944 60.1766 15.5892 59.4703 16.5786C58.764 17.5464 58.4005 18.88 58.3797 20.5791C58.3382 22.8375 58.7329 24.7625 59.5638 26.3541C60.4155 27.9457 61.5372 28.7522 62.929 28.7738Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.8659 24.6766C86.6167 24.6121 86.4085 24.6982 86.2428 24.9347C85.9099 25.623 85.3908 26.193 84.6848 26.6446C83.9993 27.0748 83.1891 27.2899 82.2543 27.2899C81.1737 27.2899 80.2389 26.978 79.45 26.3543C78.6604 25.7305 78.0687 24.8594 77.6735 23.741L85.2141 20.8696C86.2736 20.4825 86.8036 19.7297 86.8036 18.6113C86.8036 17.3638 86.2948 16.2669 85.2765 15.3205C84.2794 14.3526 82.846 13.8687 80.9764 13.8687C78.8994 13.8687 77.1339 14.6215 75.6793 16.127C74.246 17.6326 73.5297 19.5254 73.5297 21.8052C73.5297 24.2572 74.2255 26.236 75.617 27.7416C77.0298 29.2472 78.7953 29.9999 80.9141 29.9999C82.7214 29.9999 84.1862 29.4945 85.308 28.4836C86.4297 27.4727 87.0735 26.3973 87.2399 25.2573C87.2604 24.9347 87.1358 24.7411 86.8659 24.6766ZM78.0166 16.5465C78.5981 15.5786 79.3774 15.0946 80.3533 15.0946C81.1846 15.0946 81.8804 15.514 82.4413 16.3529C83.0227 17.1702 83.3138 17.966 83.3138 18.7403C83.3138 19.3855 83.0433 19.8372 82.5036 20.0953L77.3311 22.4182C77.2065 21.773 77.1442 21.0848 77.1442 20.3534C77.1442 18.7833 77.4345 17.5143 78.0166 16.5465Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_942_69">
          <rect width="88" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DandeLogo;
